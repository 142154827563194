export default {
    // baseUrl: "https://192.168.18.21:8000/",
    // baseUrl: "https://ec2-18-116-175-124.us-east-2.compute.amazonaws.com:8005/",



// ==============  new base urrl ============
    baseUrl: "https://backend.sellanywheel.com/",
    // baseUrl: "http://192.168.18.36:8000/",
    // baseUrl:"http://192.168.18.23:8000/"
    // baseUrl: "http://backend.sellanywheel.com:7000/",
  
  };
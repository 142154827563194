import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import CameraWithOverlayVideo from "../CameraWithOverlayVideo";
import CarDamgeCamra from "../CarDamgeCamra";
import Cardamagepage from "../Cardmagepage";
import CarDamge from "../Car Damge";
import ScaleLoader from "react-spinners/ScaleLoader";

import baseUrlNew from "../BaseUrl/index";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import { css } from "@emotion/react";
import { getcardata } from "../Redux/reduxflow/action";
import Lottie from "lottie-react";
import loader from "../assets/loader.json";
// import { Oval } from  'react-loader-spinner'

const queryString = require("query-string");

function ApiCall({ userId }) {
  const override = css`
    display: block;
  `;

  // const { id } = useParams();
  // // console.log("Id of user ",id)
  const [empList, setEmpList] = useState({});
  const [available, setavailable] = useState();
  // const [res, setres] = useState(["default"]);
  const [loading, setloading] = useState(true);
  let history = useHistory();

  // const [user_status, setuser_status] = useState("")

  // // console.log("api data in state", user_status);

  useEffect(() => {
    getuserStatus();
    // setres([...res," 2nd co"])
  }, []);

  let location = useLocation();

  const id = location.state.id;
  // console.log("id+++++++++ in api call ",id)

  // // console.log("local ",localStorage.getItem('user_id'))

  const baseUrl = `${baseUrlNew.baseUrl}api/v1/check_product_photos/`;

  const getuserStatus = () => {
    axios
      .post(baseUrl, {
        pk: location.state.id,
      })
      .then((response) => {
        setavailable(response.data.data.available);
        setloading(false);
      })
      .catch(({ data, status, statusText, headers, config, request }) => {});
  };
  // // console.log("chk redux state", userId);

  return loading ? (
    <>
      <center>
        <Lottie
          animationData={loader}
          style={{ height: "30%", width: "30%" }}
          autoplay={true}
        />

        {/* <ScaleLoader color='#FEF200' loading={true}  size={100} css={override} /> */}
        {/* <h1>Loading...</h1> */}
      </center>
      {/* <Oval color="#00BFFF" height={80} width={80} /> */}
    </>
  ) : available ? (
    <CarDamge id={id} car_name={location.state.car_name} />
  ) : (
    <CameraWithOverlayVideo
      id={id}
      close={setavailable}
      car_name={location.state.car_name}
    />
  );
}

const mapStateToProps = (state) => ({
  userId: state.cardata.data,
});
const mapDispatchToProps = (dispatch) => {
  return {
    // addapidata: (response) => dispatch(addapidata(response)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApiCall);

import React, { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import "./style.css";
import axios from "axios";
import { inp_chk_action, img_list_action } from "../Redux/reduxflow/action";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { SALE_PRODUCT_LIST, ADD_CAR_FEATURES ,CHAKBOX_LIST} from "../../constant";
// import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
// import Labels from "../../services/apiLabels";
import Camra from "../camera_question";

function IputTypes(props) {
  const {
    inp_chk_action,
    pics,
    cityes_List_,
    data,
    userinfo,
    divindex,
    index_of,
    img_list_action,
    showCamFunction
  } = props;
  // console.log("index_of ======index_of ", index_of);
  // console.log("data ======data=========>> ", data);

  // // console.log("chk inp click in redux =======>>>", xxxxx);

  const [slectdiv, setslectdiv] = useState(true);
  const [questionid, setquestionid] = useState();

  const [clr, setclr] = useState(true);
  const input_click = (e) => {};

  const Chkboxvaluehandler = async (e, event) => {
    if (event.target.checked) {
      var feature = [];

      if (divindex[index_of]) {
        feature = divindex[index_of];
      }

      inp_chk_action({
        div: [...feature, e.choice_id],
        index: index_of,
      });
    } else {
      const feature = divindex[index_of];
      var index = feature.indexOf(e.choice_id);
      // console.log("index==== ", index);
      if (index !== -1) {
        feature.splice(index, 1);
        inp_chk_action({
          info: feature,
          div: feature,
          index: index_of,
        });
      }
    }
  };

  const inpboxvaluehandler = async (e, id) => {
    // const data = divindex;

    // setslectDiv(id)

    // console.log("chk box e ", e, " evetn ");

    // const feature  = userinfo;
    const d = e.id;
    inp_chk_action({
      info: d,
      div: d,
      index: index_of,
    });
    //  await setUserInfo(d,

    //     );

    //     setdivindex(  (prevState)=>{
    //       // console.log("Prev state ",prevState,' in ',index_of);
    //       const f={...prevState};
    //       f[index_of]=d
    //       return f
    //     }
    //      );

    // console.log(userinfo);

    // childData({
    //   userinfo,
    //   questionid,
    // })

    // if (checked) {
    //   setUserInfo({
    //     languages: [...languages, value],

    //   });
    // }

    // // console.log("chk box e ", e.target.value);
    // // console.log("chk box e ", e.target.checked);
  };

  const [pictures, setPictures] = useState([]);
  // console.log("chk camra pics from rdeux ", pics);

  useEffect(() => {
    showCamFunction(showcamra);
  });

  const [showcamra, setshowcamra] = useState(false);
  const opencamra = () => {
    setshowcamra(true);
    
  };
  

  const save_pics = (data) => {
    // console.log("chk save_pics", data);
    img_list_action({
      imges: data.url,
      index: index_of,
    });
  };

  if (showcamra) {
    return (
      <>
        <Camra
          save_pics={save_pics}
          pictures={pictures}
          setPictures={setPictures}
          setshowcamra={setshowcamra}
        />
      </>
    );
  }

  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">{data["question"]}</div>
        <div className="showInterest_description">
          Choose that applies to your car
        </div>
      </div>
      <div className="container inp_main_wrappa">
        <div className="main_Wrap col-lg-5 col-md-6 col-sm-12 ">
          {React.Children.toArray(
            data["choices"].map((item, id) => {
              return (
                <>
                  <div
                    id="inp1"
                    className={
                      divindex[index_of]
                        ? divindex[index_of] == item.id
                          ? "inp_wrapx text-center bclas my-2"
                          : "inp_wrapx aclas text-center my-2"
                        : "inp_wrapx aclas text-center my-2"
                    }
                    onClick={() => {
                      inpboxvaluehandler(item, id);
                    }}
                  >
                    <span className="text-center">{item.choice}</span>
                  </div>

                  {/* {
                    divindex[index_of]
                      ? divindex[index_of].indexOf(item.choice_id) != 1
                        ? "inp_wrapx text-center bclas my-2"
                        : "inp_wrapx aclas text-center my-2"
                      : "inp_wrapx aclas text-center my-2"
                  } */}
                </>
              );
            })
          )}
        </div>

        <div className="image_damage_wrap p-3">
          <span onClick={opencamra}>
            <FaPlusCircle size={25} />
          </span>
          <div>
            {index_of in pics
              ? React.Children.toArray(
                  pics[index_of].map((item) => {
                    return (
                      <>
                        <img className="img_dam m-1" src={item} />
                        {/* <button type="submit" className="cross-image">
                        X
                      </button> */}
                        {/* <img className="cross-image" src={CrossIMG} onClick={}/> */}
                      </>
                    );
                  })
                )
              : null}
          </div>
        </div>
      </div>
      {/* <div className="container p-5"></div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  divindex: state.cardata.divindex,
  pics: state.cardata.pics,
  // userinfo: state.cardeailupdate.userinfo,
});
const mapDispatchToProps = (dispatch) => {
  return {
    inp_chk_action: (data) => dispatch(inp_chk_action(data)),
    img_list_action: (data) => dispatch(img_list_action(data)),
  };
};
// const mapDispatchToProps = (dispatch) => ({

//   // inp_chk_action: (data) =>
//   //   dispatch({
//   //     type: CHAKBOX_LIST,
//   //     payload: {
//   //       data,
//   //     },
//   //   }),

// });

export default connect(mapStateToProps, mapDispatchToProps)(IputTypes);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "./style.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import { MdPending } from "react-icons/md";
import baseUrlNew from "../BaseUrl/index";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { BiRightArrowAlt } from "react-icons/bi";
import { getcardetaol_ } from "../Redux/reduxflow/action";

export const Login = (props) => {
  const { getcardetaol_, step2, loading, evaluation_id_, car_detail_data } =
    props;
  const history = useHistory();
  const [loginstatus, setloginstatus] = useState();
  const [login_loading, setlogin_loading] = useState(false);

  const notify2 = () => toast("Email or Password is incorrect");

  useEffect(() => {
    // console.log("useefect main");
    if (loginstatus) {
      setlogin_loading(false);
      // console.log("useefect main in if condtion ", loginstatus);
      window.location.reload(false);
      // history.push("/")
    } else {
    }
  }, [loginstatus]);

  // const [login, setlogin] = useState(second)

  const baseUrl = `${baseUrlNew.baseUrl}api/v1/inspection_login/`;

  const Log_In_User = (email, password) => {
    axios
      .post(baseUrl, {
        email,
        password,
      })
      .then((response) => {
        // getcardetailist(response.data);

        if (response.data.data.user_id) {
          localStorage.setItem("User Id", response.data.data.user_id);
        }
        if (
          response.data.message.description == "Email or Password is incorrect"
        ) {
          setlogin_loading(false);
          // alert("Email or Password is incorrect")

          notify2();
        }
        setloginstatus(response.data.data.user_id);

        // console.log("api response log in user_id", response.data.data.user_id);
        // console.log("api response log in", response.data);
      });
  };

  // console.log("redux=========>>>>>>>data", car_detail_data);

  const [showreoder, setshowreoder] = useState(false);

  const [email, setemail] = useState();
  const [password, setpassword] = useState();

  const loginhandeler = (event) => {
    setlogin_loading(true);
    event.preventDefault();

    Log_In_User(email, password);

    // console.log("log in click", "eamil", email, "password", password);
  };

  return (
    <>
      <div className="container">
        <div>
          <form className="form-signin">
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
            <label htmlFor="inputEmail" className="sr-only">
              Email address
            </label>
            <input
              onChange={(e) => {
                setemail(e.target.value);
              }}
              type="email"
              id="inputEmail"
              className="form-control"
              placeholder="Email address"
              required
              autoFocus
            />
            <br></br>
            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>
            <input
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              type="password"
              id="inputPassword"
              className="form-control"
              placeholder="Password"
              required
            />
            <br></br>
            {login_loading ? (
              <ClipLoader size={40} />
            ) : (
              <button
                className="sign_btn"
                name="CTAButton"
                type="submit"
                onClick={loginhandeler}
              >
                Sign in
              </button>
            )}
          </form>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  car_detail_data: state.cardata.car_detail_data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getcardetaol_: (response) => dispatch(getcardetaol_(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

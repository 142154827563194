import React, { useEffect, useState } from "react";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import "./style.css";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import baseUrlNew from "../BaseUrl/index";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory ,  useParams,useLocation,
} from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import TopAppBar, {
  TopAppBarFixedAdjust, 
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
function Recoder({ setshowreoder, }) {
  const history = useHistory();
  const location = useLocation();
  var id=location.state.id
  const [file, setfile] = useState({
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    },
  });
  // console.log("filefilefilefilefile", file);
  const [desvalue, setdesvalue] = useState("")
  const des_=(e)=>{
  
    // console.log("inp vaue",e.target.value)
    setdesvalue(e.target.value)
  
  }

  const notify2 = () => toast("Please write description.");
  const notify3 = () => toast("Audio has been saved.");
  const handleAudioStop = (data) => {
    // console.log(data)
    setfile({ audioDetails: data });
  };
  const handleAudioUpload = (data) => {

if(desvalue){
  // console.log("chk audeio file", data,' file==>> ',file.audioDetails);
  var file_object = fetch(file.audioDetails.url) 
        .then(r => r.blob())
        .then(blob => {
            var file_name = Math.random().toString(36).substring(6) + '_name.mp3'; //e.g ueq6ge1j_name.pdf
            var file_object = new File([blob], file_name, {type: 'audio/mp3'});
            var formData =  new FormData();
            formData.append("audio",file_object);
            formData.append("evaluate_audio",id);
            formData.append("description",desvalue);
            uploadfile(formData);
            notify3();
            // console.log(file_object); //Output
            history.goBack();
            // setshowreoder(false)
         });
}else{
  notify2()
}

    
    // const blobFile = new File([file.audioDetails.url], 'a.mp3',{type:"audio/mp3"});
    // var formData =  new FormData();

    // // console.log("bobfile==>> ",blobFile)
    
    // console.log(options,"chk audeio file",);

    // setshowreoder(false)
  };
  const handleCountDown = (data) => {
    // console.log(data);
  };
  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setfile({ audioDetails: reset });
  };

  const baseUrl = `${baseUrlNew.baseUrl}api/v1/product_audio/`;

  const uploadfile = (data) => {
    axios
      .post(baseUrl, data)
      .then((response) => {
        // console.log("api response", response.data);
      });
  };



  return (
    <>
    <div>
      <TopAppBar fixed style={{ "top": 0,backgroundColor:"#178971"}}>
        <TopAppBarRow>
          <TopAppBarSection align='start'>
            <TopAppBarIcon navIcon tabIndex={0}>
              <IoArrowBackOutline onClick={()=>{
                history.goBack();
              }}></IoArrowBackOutline>
              {/* <MaterialIcon hasRipple icon='back' onClick={() => console.log('click')}/> */}
            </TopAppBarIcon>
            <TopAppBarTitle>Record the audio for engine start</TopAppBarTitle>
          </TopAppBarSection>
          {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
        </TopAppBarRow>
      </TopAppBar>
    </div>
    <Recorder
        record={true}
        // title={"Record Engine Sound"}
        audioURL={file.audioDetails.url}
        showUIAudio
        handleAudioStop={(data) => handleAudioStop(data)}
        handleAudioUpload={(data) => handleAudioUpload(data)}
        handleCountDown={(data) => handleCountDown(data)}
        handleReset={() => handleReset()}
        mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
      />

      <div className="container">
        <div className="row p-4">
          <h3>Description</h3>
          {/* <p> plese weite some descrbtion about this</p> */}
          <div className="col-12">
          <TextareaAutosize
      aria-label="empty textarea"
      onChange={des_}
      placeholder="please write description hare"
      style={{ width: 250 ,minHeight:100 }}
    />
          </div>
        
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Recoder;

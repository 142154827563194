import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.css";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useHistory, Link, useLocation } from "react-router-dom";

// import Labels from "../../services/apiLabels";
import { ShimmerThumbnail } from "react-shimmer-effects";
import baseUrlNew from "../BaseUrl/index";
import axios from "axios";
import { getcardetailist } from "../Redux/reduxflow/action";
import InputTypes, { showCamFunction } from "../InputTypes";
import { IoArrowBackOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import TopAppBar, {
  TopAppBarFixedAdjust,
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from "@material/react-top-app-bar";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-top-app-bar/dist/top-app-bar.css";
import "@material/react-material-icon/dist/material-icon.css";
import Logo from "../assets/images/wheellogo1.png";

export const CarUpdate = ({
  car_data,
  getcardetail,
  evaluation_id_,
  carquestionloading,
  loading,
  inp_chk_action,
  userinfo,
  divindex,
  getcardetailist,
  pics,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const location = useLocation();
  const id = location.state.id;
  const car_id = location.state.car_id;
  useEffect(() => {
    GetCar_detail();
  }, []);

  const history = useHistory();
  const baseUrl = `${baseUrlNew.baseUrl}api/v1/getoption_inspection/`;

  const GetCar_detail = () => {
    axios
      .post(baseUrl, {
        id: id,
      })
      .then((response) => {
        // console.log("api response for car update==>> ", response.data);
        if (response.data.data.inspection_title.length > 0) {
          getcardetailist(response.data);
        } else {
          history.goBack();
          toast(
            `No inspection questions are availble for ${response.data.data.title}.`
          );
        }
      });
  };

  const baseUrl2 = `${baseUrlNew.baseUrl}api/v1/create_selected_options/`;

  // console.log("xxxxxxxxxxxxxx==========>>>>>.xxxxxxxxxx", car_data, loading);

  const [index, setIndex] = useState(0);
  const [showCam, setShowCam] = useState("false");
  // const [userinfo, setUserInfo] = useState([]);

  // const [divindex, setdivindex] = useState({});
  const [divindex_multiple, setdivindex_multiple] = useState([]);
  // console.log(index,"xxxxxxxxxxxxx x www xxxxxxxxxx", car_data[index]);

  // const hideButton = (camOn) => {
  //   // console.log("Show Camera ======== ", camOn);
  //   return camOn;
  // };

  const showCamFunction = (index) => { // the callback. Use a better name
    // console.log("TRUE OR FALSE = ", index);
    setShowCam(index);
  };

  const [bar, setbar] = useState(0);

  function Buttons({ index, setIndex, size, btnHide }) {
    const doneinput = () => {
      carfeatureadd();

      history.push({
        pathname: "/carsteps",
        state: { id: car_id, car_name: location.state.car_name },
      });
    };

    const nextSlide = () => {
      console.log(
        index,
        "xxxxxxxxxxx chj index ====>>>>xxxxxxxxxxxxx",
        car_data.length
      );
      carfeatureadd();
      // console.log(index,"xxxxxxxxxxxxx x www xxxxxxxxxx", car_data[index]);
      // if(car_data[index]){

      //   carfeatureadd({

      //       })
      // }

      if (index < size) setIndex((i) => i + 1);
      setbar(bar + 100 / car_data.length);
    };
    const prevSlide = () => {
      console.log(
        index,
        "xxxxxxxxxxx chj index ====>>>>xxxxxxxxxxxxx",
        car_data.length
      );

      if (index > 0) setIndex(--index);
      setbar(bar - 100 / car_data.length);
    };

    return (
      <>
        <div>
          <TopAppBar
            fixed
            style={{ top: 0, height: 80, backgroundColor: "#178971" }}
          >
            <TopAppBarRow>
              <TopAppBarSection align="start">
                <TopAppBarIcon navIcon tabIndex={0}>
                  <IoArrowBackOutline
                    onClick={() => {
                      history.goBack();
                    }}
                  ></IoArrowBackOutline>
                  {/* <MaterialIcon hasRipple icon='back' onClick={() => console.log('click')}/> */}
                </TopAppBarIcon>
                <img
                  height={200}
                  width={200}
                  style={{ maxWidth: 80, maxHeight: 80 }}
                  src={Logo}
                  alt="Sell Any Wheel Logo"
                />
                <TopAppBarTitle>{location.state.step_name}</TopAppBarTitle>
              </TopAppBarSection>
              {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
            </TopAppBarRow>
          </TopAppBar>
          <TopAppBarFixedAdjust></TopAppBarFixedAdjust>
        </div>
        {showCam === false ? (
          <div className="container-fluied botm-wrap">
            <div className="row d-flex justify-content-center  ">
              <div className="col-6 d-flex text-center justify-content-center">
                <button
                  onClick={prevSlide}
                  className="back-btn ms-3"
                  name="CTAButton"
                >
                  {" "}
                  Previous{" "}
                </button>

                {}

                {index < car_data.length - 1 ? (
                  <button
                    onClick={nextSlide}
                    className="next-btn"
                    name="CTAButton"
                  >
                    {" "}
                    Next{" "}
                  </button>
                ) : (
                  <button
                    onClick={doneinput}
                    className="next-btn"
                    name="CTAButton"
                  >
                    {" "}
                    Done{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <ToastContainer />
      </>
    );
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const childData = (e) => {
    // console.log("chind data in parent", e);
  };

  const carfeatureadd = () => {
    console.log(
      divindex[index],
      divindex,
      "chk index for aaaaa",
      car_data,
      car_data[index]["question_id"]
    );
    console.log(pics);
    // var images=[]
    // pics.map((item)=>{
    //   images.push(...item)
    // })
    // // console.log("images===========>> ",images)
    axios
      .post(baseUrl2, {
        Car: car_id,
        question_select: car_data[index]["id"],
        choice_select: divindex[index],
        images_s: pics[index] ? pics[index] : [],
      })

      .then((response) => {
        // getcardetaol_(response.data);
        // console.log("api response", response.data);
      });
  };

  return (
    <>
      {/* <div className="aaaaaa">
        <div className="p_wrap mt-2">
          <BorderLinearProgress
            variant="determinate"
            className="progresbar"
            value={bar}
          />
        </div>
      </div> */}

      <div className="margin-top-class">
        <main>
          {/* <div className="presentation">{renderSlide()}</div> */}
          {carquestionloading ? (
            <>
              <div className="p-5">
                <ShimmerThumbnail height={160} rounded />
                <ShimmerThumbnail height={60} rounded />
                <ShimmerThumbnail height={60} rounded />
              </div>
            </>
          ) : (
            <>
              <InputTypes
                data={car_data[index]}
                index_of={index}
                showCamFunction={showCamFunction}
              />

              <Buttons
                index={index}
                setIndex={setIndex}
                size={car_data.length}
                // btnHide={hideButton}
              />
            </>
          )}
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  car_data: state.cardata.data.inspection_title,
  carquestionloading: state.cardata.carquestionloading,
  divindex: state.cardata.divindex,
  userinfo: state.cardata.userinfo,
  pics: state.cardata.pics,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getcardetailist: (response) => dispatch(getcardetailist(response)),
  };
};
// const mapDispatchToProps = (dispatch) => ({

//   // carfeatureadd: (data) =>
//   //   dispatch({
//   //     type: ADD_CAR_FEATURES,
//   //     payload: {
//   //       type: ADD_CAR_FEATURES,
//   //       method: POST,
//   //       label: Labels.add_feaderes,
//   //       hideOverlayLoader: true,
//   //       data,
//   //     },
//   //   }),

//   //   inp_chk_action: (data) =>
//   //   dispatch({
//   //     type: CHAKBOX_LIST,
//   //     payload: {
//   //       data,
//   //     },
//   //   }),

//   // getcardetail: (variant_id) =>
//   //   dispatch({
//   //     type: CAR_DETAIL_UPDATE,
//   //     payload: {
//   //       type: CAR_DETAIL_UPDATE,
//   //       label: Labels.carDetail_Update,
//   //       method: GET,
//   //       hideOverlayLoader: true,
//   //     },
//   //   }),
// });
export default connect(mapStateToProps, mapDispatchToProps)(CarUpdate);

function Presentation({ slides, index }) {
  const renderSlide = () => {
    return slides[index];
  };
  return <div className="presentation">{renderSlide()}</div>;
}


    import { ADD_CAR_DATA,UPDATE_CAR_DATA, CHAKBOX_LIST,GAT_CAR_DETAIL_LIST,GAT_CAR_DETAIL,IMAGE_LIST,SET_CAR_REQUEST,ADD_CAR_DATA_MAIN,ADD_DAMAGE_DATA_MAIN,UPDATE_IMAGES_ } from './types';


    export const getcardata = (data) => {
        // console.log("data in action",data )

        return {

            type: ADD_CAR_DATA,
            payload:data

        };

    };
    export const setcarquestionloading = (data) => {
        // console.log("data in action ===== setcarquestionloading",data )

        return {

            type: SET_CAR_REQUEST,
            payload:data

        };

    };

    export const getcardetaol_ = (data) => {
        // console.log("data in agetcardetaol_ction",data )

        return {

            type: GAT_CAR_DETAIL,
            payload:data,

        };

    };

    export const getcardetailist = (data) => {
        // console.log("data in getcardetailist action",data )

        return {

            type: GAT_CAR_DETAIL_LIST,
            payload:data

        };

    };
    export const inp_chk_action = (data) => {
        // console.log("data in action",data )

        return {

            type: CHAKBOX_LIST,
            payload:data

        };

    };
    export const img_list_action = (data) => {
        // console.log("data in action",data )

        return {

            type: IMAGE_LIST,
            payload:data

        };

    };








    export const updatedata = (data,index) => {
        console.log(index,"data in action",data )

        return {

            type: UPDATE_CAR_DATA,
            payload:data,index

        };

    };
    export const add_Damagedata = (data,) => {
        // console.log(index,"data in action",data )

        return {

            type: ADD_DAMAGE_DATA_MAIN,
            payload:{"data":data}

        };

    };
    export const add_newdata = (data,) => {
        // console.log(index,"data in action",data )

        return {

            type: ADD_CAR_DATA_MAIN,
            payload:{"data":data}

        };

    };
    export const update_car_imges = (data,index) => {
        console.log(index,"update_car_imges data in action",data )

        return {

            type: UPDATE_IMAGES_,
            payload:data

        };

    };
    
import React, { useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import baseUrlNew from "../BaseUrl/index"
import { updatedata } from "../Redux/reduxflow/action";
function Camra({  updatedata,data,index,state,view }) {
  const camera = useRef(null);
  const [image, setImage] = useState(null);

  let history = useHistory();
  const location = useLocation();
  const [variant_id, setVariant_id] = useState(location.state);

  const [type, settype] = useState(location.state);

  console.log(type, "camra page", data);

  function Post_imge(image) {
    const baseUrln = `${baseUrlNew.baseUrl}api/v1/update_product_images_Details/${data.id}`;

    axios
      .put(baseUrln, {
        key: data.key,
        type: data.type,
        image_data: image,
      })
      .then((response) => {
        updatedata(response.data, index);
        state(false)
        view(false)
      });
  }

  const Uploadpic = (data) => {
    // settype(variant_id.type)
    // setImage(camera.current.takePhoto());
    Post_imge(camera.current.takePhoto());
    // console.log(type, "camra  xxx pic", data);
  };


const donecamra=()=>{
  state(false)
  view(false)
}


  return (
    <>
      <div className="container_">
        <div className="camera_main_cont">
          <div className="camera_wrapper">
          <div
          onClick={donecamra}
           
            style={{top: "12px",left: "10px",position: "absolute", zIndex: "1000",color: "white",fontSize: "1.4rem"}}
            className="done">Done</div>
            <Camera
              facingMode="environment"
              width="100%"
              height="100%"
              ref={camera}
            />
          </div>

          <div className="tool_bar">
     
            <button
              className="round_button flex_center"
              //   onClick={Uploadpic}
              onClick={() => Uploadpic(camera.current.takePhoto())}
              //   onClick={() => take_imge(currentImage)}
            >
              <div className="round_button_ flex_center" />
            </button>

            {/* <button onClick={videoPlayerHandler}>play/pause</button> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updatedata: (response, variant_id) =>
      dispatch(updatedata(response, variant_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Camra);

import React, { useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
  useParams 
} from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { updatedata,add_newdata } from "../Redux/reduxflow/action";
import baseUrlNew from "../BaseUrl/index"
import DeskTopView from "../Desktopview";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { hatchback, videoSteps } from "../CameraWithOverlayVideo/data";
import ReactPlayer from "react-player";
import video from '../assets/hatchback-v3-motorway-400kbs-HQ.mov';
// import SafeArea from 'react-safe-area-component'
import video_android from '../assets/hatchback-v3-1104x828-30fps.webm'
function Camra2({ updatedata,data,index,state,view,setcamra_open ,imge_categary,img_type,add_newdata,index_of_data,id}) {
  // console.log("imge_categaryimge_categary ",img_type,imge_categary)
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  // const { id } = useParams();
  let history = useHistory();
  const location = useLocation();
  const videoPlayer = useRef(null);
  const video_index_ref = React.useRef(0);
  const [playVideo, setplayVideo] = React.useState(true);
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef(true);
  const arr = [].concat.apply([], Object.values(videoSteps));
  const animate = time => {
    if (previousTimeRef.current) {
      // console.log("i am in current ref")
      // const deltaTime = time - previousTimeRef.current;
      const getCurrentTime = videoPlayer.current.getCurrentTime();
      // // console.log("current time-->> ",getCurrentTime,' -- ',video_index,' ----- ',arr[video_index_ref.current])
      // if(imge_categary==arr[video_index_ref.current].title){
        if(getCurrentTime>=arr[index_of_data].start_at && getCurrentTime<=arr[index_of_data].stop_at){
          // console.log("i am in stop video")
          previousTimeRef.current=false
          setplayVideo(false)
          
          // // console.log("Video seconds--->> ",videoPlayer.current.getCurrentTime()," --- ",arr[video_index].sec);
        }
      // }
      
      
      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      // setCount(prevCount => (prevCount + deltaTime * 0.01) % 100);
    }
    
    // previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  React.useEffect(() => {
    previousTimeRef.current=playVideo
    // if(playVideo>1){
    //   // console.log("video index changed and greater then ",video_index)
      
    //   setplayVideo(true)
    // }
    
  }, [playVideo]);
  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);
  // const [variant_id, setVariant_id] = useState(location.state);

  // const [type, settype] = useState(location.state);

  // console.log(type, "camra page", location.state.image_id);

  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );
  const doOnOrientationChange=()=> {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait =========>>>>", window.orientation);

        break;
    }
  }
  window.addEventListener("orientationchange", doOnOrientationChange);
  const Uploadpic = (data) => {
    const imageSrc = camera.current.takePhoto();
  
    PostCarimge(imageSrc)
    // console.log(type, "camra  xxx pic", data);
  };
  // =========================api call section ==============
  const baseUrl =
  `${baseUrlNew.baseUrl}api/v1/product_images/`;

  const PostCarimge=(image)=> {
    axios
      .post(baseUrl, {
        name: imge_categary,
        type: img_type,
        image_data: image,

        evaluate_image: id,
      })
      .then((response) => {
        add_newdata(response.data.data)
        // console.log("api response", response.data);

        setcamra_open(false)
      });
  }




const donecamra=()=>{
  setcamra_open(false)
}


  return (
    <>
    <div className="container-fluied aaa">
    <BrowserView>
          <DeskTopView />
        </BrowserView>
    <MobileView>
    {sreen == "landscap" ?(
      <div className="container_">
        <div className="camera_main_cont">
          <div className="camera_wrapper">
          <div
          onClick={donecamra}
           
            style={{top: "12px",left: "10px",position: "absolute", zIndex: "1000",color: "white",fontSize: "1.4rem",display:"none"}}
            className="done">Done</div>
            <Camera
              facingMode="environment"
              width="100%"
              height="100%"
              ref={camera}
            />
            <div className="video_wrapper">
              
                    <ReactPlayer
                      ref={videoPlayer}
                      playing={playVideo}
                      // style={previousTimeRef.current?{"display": "None"}:{}}
                      // playing={true}/
                      playbackRate={4}
                      width="90%"
                      height="90%"
                      playsinline 
                      // onProgress={(e) => videoPlayerHandler(e)}
                      // onSeek={(e) => // console.log("react player onseek", e)}
                      url={window.navigator.platform=="iPhone"?video:video_android}
                      // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/suv-v4-30fps-1104x828.webm?alt=media&token=61a99d4c-6de4-45a3-9803-49ea470efa41"
                      // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/saloon-v3-1104x828-30fps%20(1).webm?alt=media&token=c060073c-1055-48af-83f2-3653b20be68a"
                    />
                  </div>
          </div>
          <div className="bottom_title_bar flex_center">
                  <div> {
                   imge_categary
                  }</div>
                </div>
          <div className="tool_bar">
          {!playVideo && (
            <button
              className="round_button flex_center"
              //   onClick={Uploadpic}
              onClick={() => Uploadpic(camera.current.takePhoto())}
              //   onClick={() => take_imge(currentImage)}
            >
              <div className="round_button_ flex_center" />
            </button>
          )}

            {/* <button onClick={videoPlayerHandler}>play/pause</button> */}
          </div>
        </div>
      </div>
    ):(
      <div className="Lotie_Main_Wrap">
      <div className="lotie_wrap">
        <lottie-player
          src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
          background="#48BA7E"
          speed="1"
          style={{ height: "92vh" }}
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
    )
    }
    </MobileView>
    </div>
    
    </>
  );
}

const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updatedata: (response, variant_id) =>
      dispatch(updatedata(response, variant_id)),
    add_newdata: (response, ) =>
      dispatch(add_newdata(response, )),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Camra2);

export const ADD_CAR_DATA = 'ADD_CAR_DATA';
export const SET_CAR_REQUEST = 'SET_CAR_REQUEST';


export const UPDATE_CAR_DATA = 'UPDATE_CAR_DATA';
export const ADD_CAR_DATA_MAIN = 'ADD_CAR_DATA_MAIN';
export const ADD_DAMAGE_DATA_MAIN = 'ADD_DAMAGE_DATA_MAIN';
export const UPDATE_IMAGES_ = 'UPDATE_IMAGES_';

export const CAR_DETAIL_UPDATE = "CAR_DETAIL_UPDATE";
export const ADD_CAR_FEATURES = "ADD_CAR_FEATURES";

// ===================new type===============
export const GAT_CAR_DETAIL = "GAT_CAR_DETAIL";
export const GAT_CAR_DETAIL_LIST = "GAT_CAR_DETAIL_LIST";
export const CHAKBOX_LIST = "CHAKBOX_LIST";
export const  IMAGE_LIST = " IMAGE_LIST";
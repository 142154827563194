import React, { useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { updatedata } from "../Redux/reduxflow/action";
import baseUrlNew from "../BaseUrl/index";

function Camra({
  save_pics,
  data,
  index,
  state,
  pictures,
  setshowcamra,
  setPictures,
  setimgs,
}) {
  // console.log("data in camera ", data);
  const camera = useRef(null);
  const [image, setImage] = useState(null);

  let history = useHistory();
  const location = useLocation();
  // const [variant_id, setVariant_id] = useState(location.state);

  // const [type, settype] = useState(location.state);

  // console.log(type, "camra page", location.state.image_id);

  const Uploadpic = (data) => {
    // settype(variant_id.type)
    // setImage(camera.current.takePhoto());
    // Post_imge(camera.current.takePhoto());
    // console.log(type, "camra  xxx pic", data);
    const img = camera.current.takePhoto();

    setshowcamra(false);

    save_pics({
      url: img,
    });
  };

  const donecamra = () => {};

  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  // console.log("chk the sreen state  =====>>>>>>", sreen);

  const doOnOrientationChange = () => {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait =========>>>>", window.orientation);

        break;
    }
  };

  // document.getElementById("alal").style.backgroundColor=""

  return (
    <>
      <div className="container_">
        <div className="camera_main_cont">
          <div className="camera_wrapper">
            <Camera
              facingMode="environment"
              width="100%"
              height="100%"
              ref={camera}
            />
          </div>

          <div className="tool_bar">
            <button
              className="round_button flex_center"
              onClick={() => Uploadpic(camera.current.takePhoto())}
            >
              <div className="round_button_ flex_center" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updatedata: (response, variant_id) =>
      dispatch(updatedata(response, variant_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Camra);

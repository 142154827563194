import React, { useEffect, useState, } from "react";
import { connect } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "./style.css";
import { useHistory ,  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdPending } from "react-icons/md";
import baseUrlNew from "../BaseUrl/index";
import axios from "axios";
import Recoder from "./recoder";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { BiRightArrowAlt } from "react-icons/bi";
import { getcardetaol_,setcarquestionloading } from "../Redux/reduxflow/action";
import { IoArrowBackOutline } from "react-icons/io5";
import TopAppBar, {
  TopAppBarFixedAdjust, 
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
import Logo from '../assets/images/wheellogo1.png';
export const CarStepsDealer = (props) => {
  const { getcardetaol_, step2, loading, evaluation_id_, car_detail_data,setcarquestionloading_ } =
    props;
  const history = useHistory();
  const location = useLocation();

const [carsteploading, setcarsteploading] = useState(false)
const search = window.location.search;
const params = new URLSearchParams(search);
    let  id  = params.get('id');
//   const id=location.state.id

  // console.log("ididididid",id)


  useEffect(() => {

    if(car_detail_data.length===0){
      // console.log("car_detail_data.lenght<1",car_detail_data)
      setcarsteploading(true)
      GetCar_detail();
    }
    
  }, []);

  const baseUrl = `${baseUrlNew.baseUrl}api/v1/inspection_title/`;
  const baseUrlProduct = `${baseUrlNew.baseUrl}api/v1/inspection_product`;
  const GetCar_detail = async () => {

   
     axios.get(baseUrl).then((responseMain) => {
       axios.post(baseUrlProduct,{id:id})
      .then((response) => {
          console.log("epsonse of value surl ",response)
          if(response?.data?.data && response?.data?.data?.length>0){
              let state = { ...history.location.state };
                state.car_name=response?.data?.data[0].name
                  state.report=response?.data?.data[0].report
                  state.inspect=response?.data?.data[0].inspect
                  history.replace({ ...history.location, state });
                  setcarsteploading(false)
                  getcardetaol_(responseMain.data);
          }
          
      })
      
      // console.log("api response", response.data);
    });
    
  };

  // console.log("redux=========>>>>>>>data", car_detail_data);

  const [showreoder, setshowreoder] = useState(false);

  // if (showreoder) {
  //   return (
  //     <>
  //       <Recoder id={id} setshowreoder={setshowreoder} />
  //     </>
  //   );
  // }

  return (
    <>
      <div>
        <TopAppBar
          fixed
          style={{ top: 0, height: 80, backgroundColor: "#178971" }}
        >
          <TopAppBarRow>
            <TopAppBarSection align="start">
              
              <img
                height={200}
                width={200}
                style={{ maxWidth: 80, maxHeight: 80 }}
                src={Logo}
              />
              <TopAppBarTitle>{location?.state?.car_name??""}</TopAppBarTitle>
            </TopAppBarSection>
            {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
          </TopAppBarRow>
        </TopAppBar>
      </div>
      <div className="container">
        {/* loading */}
        {carsteploading ? (
          <div className="p-5">
            <ShimmerThumbnail height={160} rounded />
            <ShimmerThumbnail height={60} rounded />
            <ShimmerThumbnail height={60} rounded />
          </div>
        ) : (
          <>
            <div
              className="car-card-step my-5"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: "/cardamagepoint",
                  state: { id: id, car_name: location.state.car_name },
                });
              }}
            >
              <div className="card-content">
                <h5>Add Damage Points</h5>
              </div>
            </div>
            <div
              className="car-card-step my-5"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: "/apicall",
                  state: { id: id, car_name: location.state.car_name },
                });
              }}
            >
              <div className="card-content">
                <h5> Car Damages </h5>
              </div>
            </div>
            <div
              className="car-card-step my-5"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: "/recordaudio",
                  state: { id: id, car_name: location.state.car_name },
                });
                // setshowreoder(true);
              }}
            >
              <div className="card-content">
                <h5>Audio Recored</h5>
              </div>
            </div>

            {React.Children.toArray(car_detail_data.map((item) => {
              return (
                <>
                  <div
                    className="car-card-step my-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setcarquestionloading_({ data: true });
                      history.push({
                        pathname: "/carupdate",
                        state: {
                          id: item.id,
                          car_id: id,
                          car_name: location.state.car_name,
                          step_name: item.title,
                        },
                      });
                    }}
                  >
                    <div className="card-content  ">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </>
              );
            }))}
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = (state) => ({
  car_detail_data: state.cardata.car_detail_data,
  loading: state.cardata.loading,
  carquestionloading:state.cardata.carquestionloading
});

const mapDispatchToProps = (dispatch) => {
  return {
    getcardetaol_: (response) => dispatch(getcardetaol_(response)),
    setcarquestionloading_: (response) => dispatch(setcarquestionloading(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarStepsDealer);

import {
  CHAKBOX_LIST,
  UPDATE_CAR_DATA,
  GAT_CAR_DETAIL,
  GAT_CAR_DETAIL_LIST,
  IMAGE_LIST,
  ADD_CAR_DATA,
  SET_CAR_REQUEST,ADD_CAR_DATA_MAIN,ADD_DAMAGE_DATA_MAIN,UPDATE_IMAGES_
} from "./types";

const INITIAL_STATE = {
  car_pics_data: [],
  car_detail_data: [],
  loading: true,
  loadingdamage: true,
  carquestionloading: true,
  divindex: {},
  data: [],
  pics: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAT_CAR_DETAIL:
      // console.log("xxxxxxxxxx", action.payload);
      return {
        ...state,
        car_detail_data: action.payload.data,
        loading: false,
      };

    case GAT_CAR_DETAIL_LIST:
      // console.log("xxxGAT_CAR_DETAIL_LISTxxxxxxx", action.payload.data);
      return {
        ...state,
        data: action.payload.data,
        carquestionloading: false,
      };
    case SET_CAR_REQUEST:
      // console.log("xxxGAT_CAR_DETAIL_LISTxxxxxxx ---- set_carquestionloading ", action.payload);
      return {
        ...state,
        carquestionloading: action.payload,
        pics:{}
      };

    case CHAKBOX_LIST:
      // console.log(
      //   // "checkbox_list ...........checkbox_list",
      //   action.payload.imges,
      //   " state ====> "
      // );
      const f = { ...state.divindex };
      f[action.payload.index] = action.payload.div;
      // return f
     

 

    
      return {
        ...state,

        divindex: f,

       
      };


    case IMAGE_LIST:
      // console.log(
      //   "checkbox_list ...........checkbox_list",
      //   action.payload.imges,
      //   " state ====> "
      // );
    
   
      // return f
      const g = { ...state.pics };
      if(action.payload.index in g){
        // console.log("i am in if ===>>>> ",g)
        g[action.payload.index] =[...g[action.payload.index],action.payload.imges];
      }
      else{
        // console.log("i am in else ===>>>> ",g)
        g[action.payload.index] =[action.payload.imges];
      }
      
      // const i = [...state.pics ];

      // var aba={}
      // // console.log("chk iiiiii",i)
      // if(i.length==0){
        
      //   aba=[action.payload.imges]
      //   // console.log("chk 1111 abababab",aba)
      // }
      // else{
      //   aba=[...i, action.payload.imges]
        
      //   console.log(action.payload.imges,"chk 22222222222 abababab",aba)
      // }

      // // console.log("chk abababab",aba)
      return {
 
        ...state,
        pics:g,
      };




      case ADD_CAR_DATA:
        // // console.log("xxxxxxxxxx",action)
          return {

            ...state,   car_pics_data:action.payload.data,
            loadingdamage:false,
          };
      case UPDATE_CAR_DATA:
// console.log("xxxxxxxxxx",action)
state.car_pics_data[action.payload.data['key']][action.index]=action.payload.data
          return {

            ...state,car_pics_data:state.car_pics_data,

          //  newArry=car_pics_data[]

          loadingdamage:false,
          };
      case ADD_CAR_DATA:
          // // console.log("xxxxxxxxxx",action)
          return {

            ...state,  
            car_pics_data:action.payload.data,
            loading:false,
          };
      case ADD_DAMAGE_DATA_MAIN:
                          // // console.log("xxxxxxxxxx",action)
          var d_=state.car_pics_data
          
          d_["damage_images_"].push(action.payload.data)
          return {

            ...state,  
            car_pics_data:d_,
            loading:false,
                          };
      case ADD_CAR_DATA_MAIN:
                                        // // console.log("xxxxxxxxxx",action)
                        var d=state.car_pics_data
                        
                        d[action.payload.data.type.toLowerCase()+"_images_"].push(action.payload.data)
                        return {
        
                          ...state,  
                          car_pics_data:d,
                          loading:false,
                                        };
                                    
        









    default:
      return state;
  }

  
};

export default reducer;

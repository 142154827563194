import { useState, useEffect } from "react";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AiFillCar } from "react-icons/ai";
import "./style.css";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
// ES6
import axios from "axios";
import Camra from "../camera_question";
import baseUrlNew from "../BaseUrl/index";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Damage from "../assets/images/carnew.jpg";
import Car1 from "../assets/images/cara.png";
import Car2 from "../assets/images/carb.png";
import Car3 from "../assets/images/carc.png";
import Car4 from "../assets/images/card.png";
import Car5 from "../assets/images/care.png";
import Logo from '../assets/images/wheellogo1.png';
import Slider from "@mui/material/Slider";
import DeskTopView from "../Desktopview";
import { IoArrowBackOutline } from "react-icons/io5";
import TopAppBar, {
  TopAppBarFixedAdjust, 
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
import IMG2 from "../assets/images/carnew.jpg";

require("@lottiefiles/lottie-player");
require('default-passive-events');

function Appoinmentupdate({ id, image, close }) {
  let history = useHistory();

  const location = useLocation();

  const notify = () => toast("Your damage point saved. You can add more or if not you can cancel and move to home");
  const notify2 = () => toast("Please Take photo of the damage and then submit.");
  const notify3 = () => toast("Please select the type of the damage.");
  useEffect(() => {
    GetCarLenged();
    Get_Car_Dmage();
  }, []);

  // const [car_imge_, setcar_imge_] = useState(location.state.data)

  // // console.log("car image =======>>>>",car_imge_)

  // =========================api call section ==============

  // // console.log("base url export", baseUrlNew);
  const baseUrl = `${baseUrlNew.baseUrl}api/insert_inspection/`;
  const baseUrl2 = `${baseUrlNew.baseUrl}api/show_legend_car/`; 
  const baseUrl3 = `${baseUrlNew.baseUrl}api/v1/track_old/`; 
  const [points, setpoints] = useState([]);
  // console.log("base url after  export", baseUrl);
  const PostCarimge = () => {
    var ship = document.getElementById("ship");
    axios
      .post(baseUrl, {
        left: right,
        top: top,
        product: location.state.id,
        Car_legends: lable,
        imgs: imgs,
      })
      .then((response) => {
        // console.log("response======   response ", response.data.data);
        setpoints(current => [...current, response.data.data]);
        setimgs("");
        setLoading(false);
        notify();
      });
  };




   const Get_Car_Dmage = () => {
    
     axios
       .post(baseUrl3, {
         product: location.state.id,
       })
       .then((response) => {
         // console.log("responseresponse=================>>>>>>>>>>>", response.data);
         setpoints(response.data.data);
         handler();
       });
   };


  const [lengeds, setlengeds] = useState([]);
  // console.log("api pointspoints=====", points);

  const GetCarLenged = () => {
    axios.get(baseUrl2).then((response) => {
      setlengeds(response.data.data);
      // console.log("api response", response.data);
    });
  };

  let [loading, setLoading] = useState(false);

  const carcamara_page = () => {
    // console.log("current label-----------------> ",lable)
    if(!lable){
      notify3()
    }
    else if (imgs) {
      PostCarimge();
      setLoading(true);
    } else {
      notify2()
    }

    // history.push("/cardamagecamra")
  };

  const [imge, setimg] = useState(Car3);

  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  // console.log("chk the sreen state  =====>>>>>>", sreen);
const handler = () => {
  var canvas = document.getElementById("viewport"),
    context = canvas.getContext("2d");
  var base_image = new Image();
  base_image.src = IMG2;
  base_image.onload = function () {
    context.drawImage(base_image, 0, 0);
  };
  canvas.addEventListener("touchstart",handleTouchEvent,false);
  canvas.addEventListener("touchmove", handleTouchEvent, false);
  canvas.addEventListener("touchcancel", handleTouchEvent, false);
  canvas.addEventListener("touchend", handleTouchEvent, false);
};


 

 

  // window.addEventListener("load", () => {
  // console.log("LOadiddi");
  const doOnOrientationChange = () => {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");
        handler();
        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait ===x======>>>>", window.orientation);

        break;
    }
  };

  window.addEventListener("orientationchange", doOnOrientationChange);
  
  const [top, settop] = useState("48px")
  const [right, setright] = useState("95px")

  // var ship = document.getElementById("ship");

  // document
  //   .getElementById("imge_wrap")
  //   .addEventListener("touchstart", handleTouchEvent, true);
  // document
  //   .getElementById("imge_wrap")
  //   .addEventListener("touchmove", handleTouchEvent, true);
  // document
  //   .getElementById("imge_wrap")
  //   .addEventListener("touchend", handleTouchEvent, true);
  // document
  //   .getElementById("imge_wrap")
  //   .addEventListener("touchcancel", handleTouchEvent, true);

  // ship.addEventListener("touchstart", handleTouchEvent, true);
  // ship.addEventListener("touchmove", handleTouchEvent, true);
  // ship.addEventListener("touchend", handleTouchEvent, true);
  // ship.addEventListener("touchcancel", handleTouchEvent, true);

  // will adjust ship's x to latest touch
  const handleTouchEvent = (e) => {
    // console.log("Touch event");
    var scrollPosition = window.pageYOffset; // current position
    var windowSize     = window.innerHeight; // window height
    var bodyHeight     = document.body.offsetHeight; 
    // // console.log("scrollPosition ",scrollPosition==0)
    if(e._reactName=="onTouchStart"){
      document.body.classList.add("stop-scrolling");
    }

    // console.log(e.target.id)
    if (e.touches.length === 0) {
      if(e._reactName=="onTouchEnd"){
        document.body.classList.remove("stop-scrolling");
      }
      
      return}
    var touch = e.touches[0];
    var targetT = document.elementFromPoint(touch.clientX, touch.clientY);
    // console.log("targetT ", targetT.id, targetT.className, targetT.classList);
    if (targetT.id == "imge_wrap") {
      var ship = document.getElementById("ship");
      var tA = document.getElementById("overmain");

      const elemWidth = ship.getBoundingClientRect().width;
      const parentWidth = ship.parentElement.getBoundingClientRect().width;

      // console.log( "Element overflow ",elemWidth > parentWidth)
      // // console.log("Overflow width ",tA.scrollWidth > tA.clientWidth)
      // // console.log("Overflow height",tA.scrollHeight > tA.clientHeight)

      // // console.log("Overflow width 2",tA.scrollWidth < tA.clientWidth)
      // // console.log("Overflow height 2",tA.scrollHeight < tA.clientHeight)
      var clientRectangle = ship.getBoundingClientRect();

      // console.log(parseFloat(ship.style.top));
      var y = clientRectangle.top - parseFloat(ship.style.top);
      var x = clientRectangle.left - parseFloat(ship.style.left);
      // console.log(
      //   "Clietn left and right ",
      //   clientRectangle.top,
      //   clientRectangle.left
      // );
      // // console.log("ship left and right ", ship.style.top, ship.style.left);
      // var positionInfo = ship.getBoundingClientRect();
      // // console.log("height ,width ",ship.offsetWidth,ship.offsetHeight,)

      // var mul = positionInfo.width*(2.3/50)
      // // console.log("multi ",mul)
      // var mul2 = positionInfo.height*(2.3/50)
      e.preventDefault();
      e.stopPropagation();
      // // console.log("Target ", e);

      // // console.log("tagetT ",targetT)
      // var target =touch.target;
      // // console.log("width ",touch.pageX,ship.width,(touch.pageX - ship.width /2));
      // // console.log("Height ",touch.pageY,ship.height,(touch.pageY -ship.height/2));
      // // console.log("x y ", x, y);
      // // console.log("px py ", touch.pageX, touch.pageY);

      // var main = document.getElementById("overmain");
      // var screenWidth = main.getBoundingClientRect().width;
      // var screenheight =main.getBoundingClientRect().height;
      // // // console.log("Touch ",(touch.pageX - x) ,screenWidth)
      // // ( screenWidth - pixels ) / screenWidth ;
      // // // console.log("screen width ",screenWidth,touch.pageX,screenWidth -touch.pageX," x ",x)
      // var percentagex = ( screenWidth -touch.pageX) / screenWidth;
      // // // console.log("per ",percentage)
      // ship.style.left = Math.abs((1-percentagex.toFixed(5))*100) + "%";
      // // console.log(ship.style.left);
      // // // console.log("screen height ",screenheight,touch.pageY,screenheight -touch.pageY," y ",y)
      // var percentagey = ( screenheight -touch.pageY) / screenheight;
      // // // console.log("per ",percentage)
      // ship.style.top = Math.abs((1-percentagey.toFixed(5))*100) + "%";

      // var Npercentage = ( screenWidth -x) / screenWidth;
      //   // console.log("Match x ",Math.abs((1-percentagex.toFixed(5))*100) , Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagex.toFixed(5))*100) + Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagex.toFixed(5))*100) - Math.abs((1-Npercentage.toFixed(5))*100))
      // var Npercentage = ( screenheight -y) / screenheight;
      // // console.log("Match x ",Math.abs((1-percentagey.toFixed(5))*100) , Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagey.toFixed(5))*100) + Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagey.toFixed(5))*100) - Math.abs((1-Npercentage.toFixed(5))*100))

      // const  main =document.getElementById('overmain')
      // // console.log("main ",main.getBoundingClientRect().width)
      // // console.log("screen width ",window.screen.width)
      ship.style.left = touch.pageX - x + "px";

      ship.style.top = touch.pageY - y - scrollPosition + "px";

      setright(ship.style.left);
      settop(ship.style.top);
    }
    if(e._reactName=="onTouchEnd"){
      document.body.classList.remove("stop-scrolling");
    }
  };
  // });

  const [lable, setlable] = React.useState('');

  // console.log("lable", lable);

  const handleChangeslect = (event) => {
    console.log(event, "event", event.target);
    setlable(event.target.value);
  };

  const [itemshow, setitemshow] = useState(false);








  const [imgs, setimgs] = useState();
  const [showcamra, setshowcamra] = useState(false);
  // console.log(showcamra, "chk imes form camra", imgs);
useEffect(() => {
  if (imgs) {
    // console.log("useEffect=============handler", imgs);
    handler();
  }
}, [ imgs]);

  const save_pics = (data) => {
    setimgs(data.url);
    // console.log(data, "chk imes form camra data");
  };
  if (showcamra) {
    return (
      <>
        <Camra save_pics={save_pics} setshowcamra={setshowcamra}   />
      </>
    );
  }




const legend = () => {
  var items = [];
  var html = "";
  return React.Children.toArray(lengeds.map((item) => {
    if (!items.includes(item.symbol)) {
      items.push(item.symbol);
      return (
        <p className="p_clor">
          <span className="pint_name">{item.symbol}:&nbsp;</span>
          {item.title}
       
        </p>
      );
    }
  }));


  
};








  return (
    <>
      {/* <div>/ */}
      <TopAppBar
        fixed
        style={{ top: 0, height: 80, backgroundColor: "#178971" }}
      >
        <TopAppBarRow>
          <TopAppBarSection align="start">
            <TopAppBarIcon navIcon tabIndex={0}>
              <IoArrowBackOutline
                onClick={() => {
                  history.goBack();
                }}
              ></IoArrowBackOutline>
              {/* <MaterialIcon hasRipple icon='back' onClick={() => console.log('click')}/> */}
            </TopAppBarIcon>
            <img
              height={200}
              width={200}
              style={{ maxWidth: 80, maxHeight: 80 }}
              src={Logo}
            />
            <TopAppBarTitle>Add Damage Points</TopAppBarTitle>
          </TopAppBarSection>
          {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust>
        <div className="container-fluied aaa">
          <BrowserView>
            <DeskTopView />
          </BrowserView>

          <MobileView>
            {sreen == "landscap" ? (
              <div className="row car_Main_Wrapw mt-2">
                <div className="btndiv">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={lable}
                        label="Type"
                        onChange={handleChangeslect}
                      >
                        {React.Children.toArray(lengeds.map((item) => {
                          return (
                            <MenuItem value={item.id}>{item.title} </MenuItem>
                          );
                        }))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <ToastContainer />

                <div className="col-12 ">
                  <div className="col-12 d-flex">
                    <div
                      className=" map maphilighted taggd  "
                      id="overmain"
                      style={{ width: "70%" }}
                    >
                      <div
                        className="buble"
                        id="ship"
                        style={{
                          top: top,
                          left: right,
                          width: "20px",
                          height: "20px",
                          zIndex: "99999",
                        }}
                        onTouchMove={handleTouchEvent}
                        onTouchCancel={handleTouchEvent}
                        onTouchStart={handleTouchEvent}
                        onTouchEnd={handleTouchEvent}
                      ></div>
                      <canvas
                        id="viewport"
                        style={{
                          width: "553px",
                          height: "406px",
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          padding: "0px",
                          border: "0px",
                        }}
                        height="406"
                        width="553"
                      ></canvas>
                      {/* <img
                      className="car_Pic"
                      id="imge_wrap"
                      src={Damage}
                      onTouchMove={handleTouchEvent}
                      onTouchCancel={handleTouchEvent}
                      onTouchStart={handleTouchEvent}
                      onTouchEnd={handleTouchEvent}
                    /> */}
                      <div
                        className="taggd-wrapper"
                        id="imge_wrap"
                        style={{ height: " 406px", width: "553px" }}
                      >
                        <img
                          alt="Car_certification_v2"
                          className="map maphilighted taggd"
                          id="image_map_tag"
                          src={IMG2}
                          style={{ display: "none" }}
                        />
                        {React.Children.toArray(points.map((item) => {
                          return (
                            <>
                              <p
                                id="point"
                                className="car_up_text"
                                style={{ top: item.top, left: item.left }}
                              >
                                {item.symbol}
                              </p>
                            </>
                          );
                        }))}
                      </div>
                    </div>

                    <div className="asas">
                      <h3 className="m-3 text-center">Legend</h3>
                      <div className="title_list">{legend()}</div>
                    </div>
                  </div>
                  <div className="btndiv">
                    <div>
                      {loading ? (
                        <span
                          className="btn text-center mx- "
                          style={{ backgroundColor: "white" }}
                        >
                          <ClipLoader loading={loading} size={40} />
                        </span>
                      ) : (
                        <button
                          name="CTAButton"
                          className="btn- text-center mt-2 "
                          onClick={carcamara_page}
                        >
                          Submit{" "}
                        </button>
                      )}

                      <button
                        name="CTAButton"
                        className="btn- text-center my-2 mx-2"
                        onClick={() => {
                          setshowcamra(true);
                        }}
                      >
                        Take Photo{" "}
                      </button>
                      <button
                        name="CTAButton"
                        className="btn- text-center my-2"
                        onClick={() => {
                          history.push({
                            pathname: "/carsteps",
                            state: {
                              id: location.state.id,
                              car_name: location.state.car_name,
                            },
                          });
                        }}
                      >
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Lotie_Main_Wrap">
                <div className="lotie_wrap">
                  <lottie-player
                    src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                    background="#48BA7E"
                    speed="1"
                    style={{ height: "92vh" }}
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              </div>
            )}
          </MobileView>
        </div>
      </TopAppBarFixedAdjust>
      {/* </div> */}
    </>
  );
}

export default Appoinmentupdate;

import React, { useEffect } from "react";
import "./App.css";
import CameraWithOverlayVideo from "./CameraWithOverlayVideo";
import CarDamgeCamra from "./CarDamgeCamra";
import Cardamagepage from "./Cardmagepage";
import CarDamge from "./Car Damge";
import ApiCall from "./ApiCall";
import Camra from "./Camra";
import Notfound from "./Blank";
import Car_Steps from "./Car_Steps";
import CarStepsDealer from "./Car_Steps_Dealer";
import UserCarList from "./User Car List";
import CarUpdate from "./CarUpdate";
import Login from "./Log in";
import Recoder from "./Car_Steps/recoder";
import {createBrowserHistory} from 'history';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

const queryString = require("query-string");
export const customHistory = createBrowserHistory(); 
const App = () => {
  // console.log("localStorage.getItem", localStorage.getItem("User Id"));

  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "instant",
  //   });
  // }, [location]);
  console.warn = () => {};
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact>
            {localStorage.getItem("User Id") == null ? (
              <>
                <Login />
              </>
            ) : (
              <UserCarList />
            )}
          </Route>

    
          <Route path="/carsteps" component={Car_Steps} />
          <Route path="/dealerinspection" component={CarStepsDealer} />
          <Route path="/recordaudio" component={Recoder} />
          <Route path="/carupdate" component={CarUpdate} />
          <Route path="/cardamagepoint" component={Cardamagepage} />
          <Route path="/apicall" component={ApiCall} />
          <Route path="/damge" component={CarDamge} />
          <Route path="/video" component={CameraWithOverlayVideo} />
        </Switch>
      </Router>
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "./style.css";
import { useHistory } from "react-router-dom";
import { MdPending } from "react-icons/md";
import baseUrlNew from "../BaseUrl/index";
import axios from "axios";
import dateFormat, { masks } from "dateformat";
import Recoder from "./recoder";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { BiHandicap, BiRightArrowAlt } from "react-icons/bi";
import { getcardetaol_ } from "../Redux/reduxflow/action";
import { CgLogOut } from "react-icons/cg";
import TopAppBar, {
  TopAppBarFixedAdjust,
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from "@material/react-top-app-bar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-top-app-bar/dist/top-app-bar.css";
import "@material/react-material-icon/dist/material-icon.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import Logo from "../assets/images/wheellogo1.png";

export const UserCarList = (props) => {
  const { getcardetaol_, step2, loading, evaluation_id_, car_detail_data } =
    props;
  const history = useHistory();

  const [usercar_list, setusercar_list] = useState([]);
  const [loding_list, setloding_list] = useState(true);
  const [proceed_option, setproceed_option] = useState(0);

  var ref = React.useRef("0");

  useEffect(() => {
    User_Car_List();
    // console.log("Data ====== ", car_detail_data.data);
    // console.log("Data Message ====== ", car_detail_data);
  }, []);

  useEffect(() => {
    // User_Car_List();
    if (proceed_option === 1) {
      confirmAlert({
        title: "Confirm to submit",
        message: "Select the Section to make this car available for",
        buttons: [
          {
            label: "Auction",
            onClick: () => {
              axios
                .post(`${baseUrlNew.baseUrl}api/v1/move_inspection/`, {
                  choice: "auction",
                  car_id: ref.current,
                })
                .then((response) => {
                  // console.log("response in auction now --->>> ", response);
                  toast(
                    "The car has been added in auction. Please contact administrator to provide further details."
                  );
                });
            },
          },
          {
            label: "Buy Now",
            onClick: () => {
              axios
                .post(`${baseUrlNew.baseUrl}api/v1/move_inspection/`, {
                  choice: "buynow",
                  car_id: ref.current,
                })
                .then((response) => {
                  // console.log("response in buy now --->>> ", response);
                  toast(
                    "The car has been added in buy now section.  Please contact administrator to provide further details."
                  );
                  // getcardetailist(response.data);
                });
            },
          },
        ],
      });
    }
  }, [proceed_option]);

  const baseUrl = `${baseUrlNew.baseUrl}api/v1/inspection_cars/`;
  const User_Car_List = () => {
    axios
      .post(baseUrl, {
        user: localStorage.getItem("User Id"),
      })
      .then((response) => {
        // getcardetailist(response.data);
        setusercar_list(response.data.data);
        setloding_list(false);
        // console.log("api response User_Car_List ", response.data);
      });
  };

  // console.log("redux=========>>>>>>>data", car_detail_data);

  const [showreoder, setshowreoder] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      {usercar_list.length <= 0 ? (
        <>
          <div>
            <TopAppBar
              fixed
              style={{ top: 0, height: 80, backgroundColor: "#178971" }}
            >
              <TopAppBarRow>
                <TopAppBarSection align="start">
                  <TopAppBarIcon navIcon tabIndex={0}>
                    <CgLogOut
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload(false);
                      }}
                    ></CgLogOut>
                    {/* <MaterialIcon hasRipple icon='back' onClick={() => console.log('click')}/> */}
                  </TopAppBarIcon>
                  <img
                    src={Logo}
                    height={200}
                    width={200}
                    style={{ maxWidth: 80, maxHeight: 80 }}
                    alt="Sell Any Wheel Logo"
                  />
                  <TopAppBarTitle>Inspection</TopAppBarTitle>
                </TopAppBarSection>
                {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
              </TopAppBarRow>
            </TopAppBar>
          </div>
          <div className="container">
            <h4>No Data Found</h4>
          </div>
        </>
      ) : (
        <>
          <div>
            <TopAppBar
              fixed
              style={{ top: 0, height: 80, backgroundColor: "#178971" }}
            >
              <TopAppBarRow>
                <TopAppBarSection align="start">
                  <TopAppBarIcon navIcon tabIndex={0}>
                    <CgLogOut
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload(false);
                      }}
                    ></CgLogOut>
                    {/* <MaterialIcon hasRipple icon='back' onClick={() => console.log('click')}/> */}
                  </TopAppBarIcon>
                  <img
                    src={Logo}
                    height={200}
                    width={200}
                    style={{ maxWidth: 80, maxHeight: 80 }}
                    alt="Sell Any Wheel Logo"
                  />
                  <TopAppBarTitle>Inspection</TopAppBarTitle>
                </TopAppBarSection>
                {/* <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon 
                aria-label="print page" 
                hasRipple 
                icon='print' 
                onClick={() => console.log('print')}
              />
            </TopAppBarIcon>
          </TopAppBarSection> */}
              </TopAppBarRow>
            </TopAppBar>
          </div>
          <div className="container">
            {loding_list ? (
              <div className="p-5">
                <ShimmerThumbnail height={160} rounded />
                <ShimmerThumbnail height={60} rounded />
                <ShimmerThumbnail height={60} rounded />
              </div>
            ) : (
              <>
                <div className="row">
                  {React.Children.toArray(usercar_list.map((item, index) => {
                    return (
                      <>
                        <div className="col-lg-6 col-12 " key={index}>
                          <div className="step-card" key={index}>
                            <div className="" key={index}>
                              <div>
                                <h5 className="itemName" key={index}>
                                  {item.name}
                                </h5>
                                <h6 className="card-subheading">
                                  {dateFormat(
                                    item.assign_data,
                                    " mmmm dS, yyyy "
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="">
                              <div className="column d-flex justify-content-center">
                                <div
                                  className="progress position-relative"
                                  style={{
                                    height: "30px",
                                    width: "40%",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    className="progress-bar progress-color"
                                    role="progressbar"
                                    style={{
                                      width: item.inspect + "%",
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                    aria-valuenow={item.inspect}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    <small className="justify-content-center d-flex position-absolute w-100">
                                      Vehicle Report: {item.inspect}%
                                    </small>
                                  </div>
                                </div>

                                {/* <div style={{ width: 100, height: 100 }}> */}
                                {/* <CircularProgressbar value={item.inspect} text={`${item.inspect}%`} />; */}
                                {/* </div> */}
                              </div>
                            </div>
                            <div className="btn-class">
                              <div className="btn-class-2">
                                {!item.available ? (
                                  item.proceed ? (
                                    <>
                                      <h6 className="card-text">
                                        <span>
                                          The inspection results are good enough
                                          to proceed further.
                                        </span>
                                      </h6>
                                    </>
                                  ) : (
                                    <h6 className="card-text">
                                      <span>
                                        The inspection results are not good to
                                        proceed further.
                                      </span>
                                    </h6>
                                  )
                                ) : (
                                  <>
                                    <h6 className="card-text">
                                      <span>
                                        This inspection is already shifted to{" "}
                                        {item.at} section
                                      </span>
                                    </h6>
                                  </>
                                )}
                                <button
                                  onClick={handleShow}
                                  className="btn_contact"
                                  type="submit"
                                >
                                  Information
                                </button>

                                <Modal show={showModal} onHide={handleClose}>
                                  <Modal.Header
                                    closeButton
                                    className="modal-header"
                                  >
                                    <Modal.Title>
                                      Vehicle Information
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className="col-md-12">
                                      <div className="table-responsive">
                                        <table className="table table-user-information">
                                          <tbody>
                                            <tr>
                                              <td className="heading">
                                                <strong>Note</strong>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.note}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Provider Name</strong>
                                              </td>
                                              <td>{item.provider_name}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Email</strong>
                                              </td>
                                              <td>{item.email}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Mobile Number</strong>
                                              </td>
                                              <td>{item.mobile_number}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>City</strong>
                                              </td>
                                              <td>{item.city}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Post Code</strong>
                                              </td>
                                              <td>{item.post_code}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Address</strong>
                                              </td>
                                              <td>{item.address}</td>
                                            </tr>
                                            <tr>
                                              <td className="heading">
                                                <strong>Country</strong>
                                              </td>
                                              <td>{item.country}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <button
                                      type="submit"
                                      className="btn_contact"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                                <button
                                  onClick={() => {
                                    history.push({
                                      pathname: "/carsteps",
                                      state: {
                                        id: item.car_id,
                                        car_name: item.name,
                                        report: item.report,
                                        inspect: item.inspect,
                                      },
                                    });
                                  }}
                                  className="btn_contact"
                                  type="submit"
                                >
                                  Inspection
                                </button>

                                {!item.available ? (
                                  item.proceed ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          setproceed_option(0);
                                          confirmAlert({
                                            title: "Confirm to submit",
                                            message:
                                              "Are you sure you want to proceed this inspection further?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  // alert('yes')
                                                  ref.current = item.car_id;
                                                  setproceed_option(1);
                                                },
                                              },
                                              {
                                                label: "No",
                                                onClick: () =>
                                                  setproceed_option(3),
                                              },
                                            ],
                                          });
                                        }}
                                        className="btn_contact btn-space"
                                        type="submit"
                                      >
                                        Proceed
                                      </button>
                                    </>
                                  ) : null
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }))}
                </div>
                <ToastContainer />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  car_detail_data: state.cardata.car_detail_data,
  loading: state.cardata.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getcardetaol_: (response) => dispatch(getcardetaol_(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCarList);
